import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/content.js";
import WelcomeInscription from 'src/components/inscription/welcome';
import SEO from 'src/components/seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{`Inscription`}</h2>
    <SEO title="Inscription" mdxType="SEO" />
    <p>{`Merci pour l'intéret que vous portez à l'activité proposée par l'enfant d'eau.`}</p>
    <p>{`L'enfant d'eau est une association à but non lucratif. Elle est gérée par des
parents bénévoles et son existence dépend de la contribution de chacun.`}</p>
    <WelcomeInscription mdxType="WelcomeInscription" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      