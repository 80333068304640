import React from 'react';

import Loading from 'src/components/design-system/loading';
import { useConfig } from 'src/components/configuration';
import { Link } from 'gatsby';

import classnames from 'classnames';

const CTA = ({ disabled = false, ...props }) => {
  const colorClassName = disabled
    ? `bg-gray-600 hover:bg-gray-500 focus:border-gray-700 focus:shadow-outline-gray- active:bg-gray-700`
    : `bg-blue-600 hover:bg-blue-500 focus:border-blue-700 focus:shadow-outline-blue- active:bg-blue-700`;
  return (
    <Link
      {...props}
      className={classnames(
        'inline-flex w-full md:w-auto items-center px-8 py-4 border border-transparent text-sm leading-4 font-medium rounded-md text-white focus:outline-none transition ease-in-out duration-150 m-auto',
        colorClassName,
        {
          'pointer-events-none cursor-not-allowed': disabled,
        }
      )}
    />
  );
};

const WelcomeInscription = () => {
  const requestState = useConfig();
  return (
    <div>
      <Loading requestState={requestState}>
        {(rs) => {
          if (rs.data && rs.data.data) {
            const { inscription_ouverte, reinscription_ouverte } = rs.data.data;

            if (inscription_ouverte !== 0 || reinscription_ouverte !== 0) {
              return (
                <div className="flex flex-col sm:flex-row  mt-4 w-full items-start">
                  <CTA
                    to="/inscription/infos"
                    disabled={inscription_ouverte === 0}
                  >
                    Nouvel adhérent
                  </CTA>
                  <br className="mt-4 md:hidden" />
                  <CTA
                    to="/famille/inscription"
                    disabled={reinscription_ouverte === 0}
                  >
                    Réinscription
                  </CTA>
                </div>
              );
            }
          }
          return (
            <div>Les inscriptions ne sont pas ouvertes pour le moment.</div>
          );
        }}
      </Loading>
    </div>
  );
};

export default WelcomeInscription;
